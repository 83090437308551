














import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'FormSection'
})
export default class extends Vue { }
