
























































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { BookingSnap, AimsCodeWithDescription } from '@ht-lib/accounts-common'
import { Account, Job, PartialDeep } from '@ht-lib/accounts-models'
import HorizontalStackedText from './HorizontalStackedText.vue'
import FormSection from './FormSection.vue'
import JobTypeComponent from './job/JobType.vue'
import { ContactVenue, StackedText } from '@ht-vue/misc'
import Photographers from './job/Photographers.vue'
import Notes from './job/Notes.vue'
import Images from './job/Images.vue'
import { ChildProtectionPreview } from './JobTypeContainer.vue'
import { format } from 'date-fns'

@Component({
  name: 'Worksheet',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { FormSection, StackedText, HorizontalStackedText, JobTypeComponent, ContactVenue, Photographers, Notes, Images }
})
export default class extends Vue {
  @Prop({ required: true }) job!: Job
  @Prop({ required: true, default: [] }) onlineJobTypes!: AimsCodeWithDescription[]
  @Prop({ required: true, default: [] }) childProtectionPreviewUrls!: ChildProtectionPreview[]
  @Prop({ required: true }) booking!: BookingSnap
  @Prop({ required: true }) account!: Account
  @Prop({ required: true }) jobId!: string

  navigating = false
  private modalType = ''

  get showModal (): boolean {
    return this.modalType !== ''
  }

  get jobType (): string {
    return this.onlineJobTypes.find(x => x.id === this.job.jobType)?.description
  }

  get takeDate (): string {
    return this.job.takeDate
      ? this.worksheetDateFormat(this.job.takeDate)
      : 'No dates set'
  }

  get disableContinue () {
    return this.navigating || !!this.canSaveMessage
  }

  get dispatchAddress (): string {
    return (this.job.account.dispatchAddress?.address ?? '')
      .split(',')
      .map((segment: string) => segment.trim())
      .join(',\n')
  }

  get halfTermClose (): string {
    return this.job.account.halfTerm
      ? this.worksheetDateFormat(this.job.account.halfTerm.close) + ' - ' + this.worksheetDateFormat(this.job.account.halfTerm.open)
      : 'No dates set'
  }

  get fullTermClose (): string {
    return this.job.account.fullTerm
      ? this.worksheetDateFormat(this.job.account.fullTerm.close) + ' - ' + this.worksheetDateFormat(this.job.account.fullTerm.open)
      : 'No dates set'
  }

  get canSaveMessage () {
    if (this.job.market === 'G') {
      return ''
    }
    let message = ''

    if (!this.job.account.contact) {
      message += 'No Contact Set '
    }

    if (!this.job.account.dispatchAddress) {
      message += 'No Dispatch Address Set'
    }

    if (!message) {
      return ''
    }

    return `Cannot Review Review Job: ${ message }`
  }

  @Emit('input')
  private updateJob (update: PartialDeep<Job>): PartialDeep<Job> {
    setTimeout(() => this.$forceUpdate(), 100)
    return update
  }

  @Emit('finished')
  private done () {
    console.log('Finished')
    this.navigating = true
  }

  close () {
    this.modalType = ''
  }

  private async goToAccountsPage (): Promise<void> {
    await this.$router.push('/accounts')
  }

  private worksheetDateFormat (date: Date | number | string): string {
    console.log(date)
    const _date = typeof date === 'string' ? Date.parse(date) : date
    return format(_date, 'd MMM yyyy')
  }
}
